
import { IConfiguredProduct } from "@models/entity-models";
import { ISceneLoadedData } from "@models/models";
import { __extends as tslib__extends } from "tslib";
import { KbEvent } from "../tools/kb-event";
import { IMessage } from "../tools/models";

//manually import what we use from tslib so it gets tree-shaked
export const __extends = tslib__extends;

//get the url this script was loaded from
//can replace this with document.currentScript when support for more browsers is there: https://developer.mozilla.org/en-US/docs/Web/API/document.currentScript
var scripts = document.getElementsByTagName("script");
var urlArr = scripts[scripts.length - 1].src.split("/");
var kbmaxUrl = urlArr[0] + "//" + urlArr[2];

export interface IEmbedArguments {
    /**
    * the element to add the 3d viewer to
    */
    elementSelector: string;
    /**
    * overrides the element selector and assumes it's an html id
    */
    elementId?: string;
    /**
    * the url to kbmax including the protocol, company subdomain and domain... if left empty will be figured out from the url this script was loaded from
    */
    kbmaxUrl?: string;
    /**
    * OBSOLETE!  Use layoutSettings parameter instead.
    * whether or not to show the kbmax page & field ui options
    */
    showFields?: boolean;
    /**
     * whether or not to show the portal header
     */
    showHeader?: boolean;
    /**
     * OBSOLETE!  Use layoutSettings parameter instead.
     * whether or not to show the configurator header
     */
    showConfigHeader?: boolean;
    /**
     * whether or not to show the configurator drawer
     */
    showDrawer?: boolean;
    /**
    * show the move me animation on scene load
    */
    showMove?: boolean;
    /**
    * a css selector for a form whose child values will be sent to scene when changed. Assumes input/select names are the same as the field names in the scene
    */
    bindToFormSelector?: string;
    /**
     * The 3 letter iso of the currency to show pricing in.  Note that the currency specified
     * must be an allowed currency as specified in the currency settings of the kbmax company.
     * If saveProductAndSubmit() is called, this currency will also be set as the currency of
     * the created quote.
     */
    currency?: string;
    /**
     * Can be used to override the theme of the company for this embed.
     */
    idTheme?: number;
    /**
     * Starting field values.  Helps to avoid FOUC from waiting for onLoaded to set field values 
     */
    fields?: {};

    /**
     * Override the layout settings set in the configurator.
     */
    layoutSettings?: {};

    /**
     * Custom parameters that will be available in snap rules (as the 'parameters' map variable)
     */
    parameters?: {};
    
    /**
    * whether to show the kbmax logo or not (required by some license agreements with KBMax)
    */
    //logo?: boolean;

    /**
     * Whether to immediately attempt to login via SSO upon loading the iframe before continuing.
     */
    autoSsoLogin?: boolean;

    /**
     * Pass-thru cookie for advanced authentication scenarios
     */
    cookieToken?: string;
}

export interface ISceneEmbedArguments extends IEmbedArguments {
    /**
    * the kbmax scene id that should be displayed
    */
    sceneId?: number;
    /**
    * the guid of the scene (needed to do default loading image)
    */
    sceneGuid?: string;
    /**
    * set to true to have a transparent background behind the scene
    */
    transparentBackground?: boolean;

}

export interface IConfigEmbedArguments extends IEmbedArguments {
    /**
    * the product id of the configurator to open
    */
    configuratorId?: number;

    /**
    * to open an existing quote product, you must fill in configuredProductId and quoteId instead of configuratorId
    */
    configuredProductId?: number;
    /**
    * to open an existing quote product, you must fill in configuredProductId and quoteId instead of configuratorId
    */
    quoteId?: number;
    /**
     * Specifies a configured product to load immediately. This is similar to setConfiguredProduct except by sending
     * the configured product as an embed arg, it will defer the loaded rule until *after* the configured product is
     * applied, which will better reflect the actual behavior of loading an existing configuration from a quote using
     * the portal.
     */
    configuredProduct?: IConfiguredProduct;

    /**
    * DO NOT USE.  OBSOLETE.  USE configuratorId INSTEAD
    */
    id?: number; //only for backwards compatibility... use configuratorId instead

    /**
     * Kinetic ERP parameters
     */
    kineticCompany?: string;

    kineticPlant?: string;

    kineticCustomerId?: string;

    kineticCustomerNumber?: string;

    kineticQuoteNumber?: string;

    kineticQuoteLine?: string;

    kineticOrderNumber?: string;

    kineticOrderLine?: string;

    kineticJobNumber?: string;

    kineticAssemblySeq?: string;

    kineticCallContext?: string;

    kineticUserId?: string;
}




export abstract class Embed<TArgs extends IEmbedArguments>{
    protected elem: HTMLElement;
    protected _iframe: HTMLIFrameElement;
    protected _logo: HTMLAnchorElement;
    protected _messageQueue: IMessage[] = [];
    protected _loading: boolean = true;
    protected _onload: () => void;
    protected _messageHandler: (event) => void;
    protected _callbackMap: any = {};
    protected _correspondenceId: number = 1;

    public onMessage = new KbEvent<IMessage>();
    public onSubmit = new KbEvent<IMessage>();

    constructor(protected args: TArgs) {
        if (!args.kbmaxUrl) {
            args.kbmaxUrl = kbmaxUrl;
        }

        if (args.elementId) {
            args.elementSelector = "#" + args.elementId;
        }

        this.fillLayoutSettings();

        var elem = this.elem = <HTMLElement>document.querySelector(args.elementSelector);
        if (!elem) {
            throw new Error("no element was found with the given selector of '" + args.elementSelector + "'");
        }

        //make the main elem relative to size iframe and loading image
        if (window.getComputedStyle(elem, null)["position"] !== "absolute") {
            elem.style.position = "relative";
        }

        //if the iframe has already been loaded, then the user is just trying to regain the reference
        this._iframe = <HTMLIFrameElement>this.elem.querySelector("iframe");
        if (this._iframe) {
            this._loading = !this._iframe.hasAttribute("data-kbmax-loaded");
        }

        //we wait for the sceneLoaded event to be broadcasted by kbmax
        if (this._loading) {
            if (!this._messageHandler) {
                this._messageHandler = (event) => {
                    var msg: IMessage = event.data;
                    if (msg && msg.name) {
                        var command = msg.name.toLowerCase();
                        if (command == "configuratorloaded" || command == "sceneloaded") {
                            var sceneLoadedArgs: ISceneLoadedData = msg.data;
                            if (sceneLoadedArgs.requestId == args.elementSelector) { //make sure this message is for the iframe this viewer3d is tied to
                                this._loading = false;
                                this._iframe.setAttributeNode(document.createAttribute("data-kbmax-loaded"));

                                //fade away loading image if it's there

                                //run all messages in the queue
                                this._messageQueue.forEach((message) => {
                                    this._sendMessage(message);
                                });
                                if (this._onLoaded) {
                                    this._onLoaded();
                                }
                            }
                        } else if (command == "submit") {
                            this.onSubmit.trigger(msg);
                        } else if (command == "readytoloadconfiguredproduct") {
                            this.readyToLoadConfiguredProduct();
                        } else if (command == "loginsso") {
                            window.location.href = args.kbmaxUrl + "/ssosignin?RedirectUrl=" + window.location.href;
                        } else {
                            if (msg.correspondenceId) {
                                var callback = this._callbackMap[parseInt(msg.correspondenceId)];
                                if (callback) callback(msg.data);
                            } else {
                                this.onMessage.trigger(msg);
                            }
                        }
                    }
                }
                window.addEventListener("message", this._messageHandler);
            }
        }

        if (!this._iframe) {
            var src = this._getIFrameSrc();

            //add the iframe to the DOM before setting it's src.  This stops it from blocking the outer page window.load event(which is bad for SEO)
            this._iframe = document.createElement("iframe");

            //now set attributes
            this._iframe.src = src;
            this._iframe.setAttributeNode(document.createAttribute("allowfullscreen"));
            this._iframe.setAttribute("allow", "xr-spatial-tracking 'self' " + this.args.kbmaxUrl + "; fullscreen *");
            this._iframe.style.width = "100%";
            this._iframe.style.height = "100%";
            this._iframe.style.position = "absolute";
            this._iframe.style.border = "none";

            this.elem.insertBefore(this._iframe, this.elem.firstChild);

            this._logo = <HTMLAnchorElement>this.elem.querySelector("a");
            if (this._logo) {
                this._logo.href = "https://www.kbmax.com";
                this._logo.target = "_blank";
                this._logo.style.position = "absolute";
                this._logo.style.right = "20px";
                this._logo.style.bottom = "20px";
                this._logo.style.width = "12%";
                this._logo.style.textAlign = "right";
                var logoImage = <HTMLImageElement>this._logo.querySelector("img");
                if (logoImage) {
                    logoImage.src = args.kbmaxUrl + "/images/kbmax_logo_horizontal.png";
                    logoImage.alt = "3D Configurator by KBMax";
                    logoImage.style.maxHeight = "108px";
                    logoImage.style.maxWidth = "160px";
                    logoImage.style.height = "auto";
                    logoImage.style.width = "100%";
                }
            }


            //setup form binding.  We do it here so it's only bound once to the event (in case there are multiple calls to initialize the same viewer)
            if (args.bindToFormSelector) {
                var formElements = document.querySelectorAll(args.bindToFormSelector + " input, " + args.bindToFormSelector + " select, " + args.bindToFormSelector + " textarea");
                for (var n = 0; n < formElements.length; n++) {
                    var formElement: HTMLElement = <HTMLElement>formElements[n];
                    this.watchElementForChange(formElement);
                }
                //observe the form for changes, so we can process newly added inputs to the forms
                if (window["MutationObserver"]) {
                    var observer = new MutationObserver((mutations) => {
                        mutations.forEach(m => {
                            if (m.type == "childList") {
                                for (var n = 0; n < m.addedNodes.length; n++) {
                                    var node = m.addedNodes[n];
                                    if (node instanceof HTMLInputElement || node instanceof HTMLSelectElement || node instanceof HTMLTextAreaElement) {
                                        this.watchElementForChange(node);
                                    }
                                }
                            }
                        });
                        if (mutations.some(m => m.type == "childList")) {
                            this.setFieldsFromForm(this.args.bindToFormSelector); //update from the form so there is immediate binding
                        }
                    });

                    //observe all the forms
                    var forms = document.querySelectorAll(args.bindToFormSelector);
                    for (var n = 0; n < forms.length; n++) {
                        var form = forms[n];
                        observer.observe(form, { childList: true, subtree: true });
                    }
                }


                this.setFieldsFromForm(args.bindToFormSelector);
            }
        }
    }

    protected watchElementForChange(elem: HTMLElement) {
        elem.addEventListener("change", () => {
            this.setFieldsFromForm(this.args.bindToFormSelector);
        });
    }

    /**
     * gets the src url for the iframe from the derived class
     */
    protected abstract _getIFrameSrc(): string;

    private _onLoaded: () => void;
    public onLoaded(callback: () => void) {
        this._onLoaded = callback;
    }

    protected _sendMessage(message: IMessage) {
        if (this._loading) {
            this._messageQueue.push(message);
        } else {
            this._iframe.contentWindow.postMessage(message, "*");
        }
    }

    protected requireWebGL() {
        //if the browser doesn't support webgl, then add the loading image and bail
        if (!this.supportsWebGL()) {
            return;
        }
    }

    protected readyToLoadConfiguredProduct() {
    }

    //serializes a form to an object that can be sent to kbmax embed api
    protected serializeFormToObject(formSelector: string) {
        var o = {};
        var form: HTMLFormElement = <HTMLFormElement>document.querySelector(formSelector);
        if (form) {
            var inputs = form.querySelectorAll("input, select, textarea");
            for (var i = 0; i < inputs.length; i++) {
                var elem: HTMLElement = <HTMLElement>inputs[i];
                var name = elem.getAttribute("kbmax-name") || elem.getAttribute("data-kbmax-name") || elem.getAttribute("name");
                if (name && !elem.getAttribute("disabled")) {
                    if (elem.getAttribute("type") === "checkbox") {
                        var input: HTMLInputElement = <HTMLInputElement>elem;
                        o[name] = input.checked;
                    } else if (elem.getAttribute("type") === "radio") {
                        var input: HTMLInputElement = <HTMLInputElement>elem;
                        if (input.checked) {
                            o[name] = input.value;
                        }
                    } else if (elem.tagName === "select") {
                        var select: HTMLSelectElement = <HTMLSelectElement>elem;
                        if (select.multiple) {
                            o[name] = [];
                            var options = select.getElementsByTagName("option");
                            for (var j = 0; j < options.length; j++) {
                                var option: HTMLOptionElement = options[j];
                                if (option.selected) {
                                    o[name].push(option.value);
                                }
                            }
                        } else {
                            o[name] = select.value;
                        }
                    }
                    else {
                        o[name] = (<any>elem).value;
                    }
                }
            }
        }

        return o;
    }

    public supportsWebGL(): boolean {
        try {
            var canvas = document.createElement('canvas');
            return !!((<any>window).WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
        } catch (e) {
            return false;
        }
    }

    public setFieldsFromForm(formSelector: string) {
        this.setFields(this.serializeFormToObject(formSelector));
    }

    public render() {
        var msg: IMessage = {
            name: "render",
            data: undefined
        }
        this._sendMessage(msg);
    }

    public snapshot(options: clara2.ISnapshotOptions, callback: (result: Blob | string) => void) {
        var correspondenceId = this._correspondenceId++;
        this._callbackMap[correspondenceId] = callback;
        this._sendMessage({
            name: "snapshot",
            data: options,
            correspondenceId: correspondenceId.toString()
        });
    }

    public setFields(fields: {}, callback?: () => void) {
        var correspondenceId = this._correspondenceId++;
        this._callbackMap[correspondenceId] = callback;
        var msg: IMessage = {
            name: "setFields",
            data: fields,
            correspondenceId: correspondenceId.toString()
        }
        this._sendMessage(msg);
    }

    public getFields(callback: (fields: {}) => void) {
        var correspondenceId = this._correspondenceId++;
        this._callbackMap[correspondenceId] = callback;
        var msg: IMessage = {
            name: "getFields",
            data: {},
            correspondenceId: correspondenceId.toString()
        };
        this._sendMessage(msg);
    }

    public runAction(actionName: string, callback?: () => void) {
        var correspondenceId = this._correspondenceId++;
        this._callbackMap[correspondenceId] = callback;
        var msg: IMessage = {
            name: "runAction",
            data: actionName,
            correspondenceId: correspondenceId.toString()
        }
        this._sendMessage(msg);
    }

    public runSubmitRule(callback?: () => void) {
        var correspondenceId = this._correspondenceId++;
        this._callbackMap[correspondenceId] = callback;
        var msg: IMessage = {
            name: "runsubmitrule",
            data: {},
            correspondenceId: correspondenceId.toString()
        };
        this._sendMessage(msg);
    }

    public getPrice(callback: (priceObject: {}) => void) {
        var correspondenceId = this._correspondenceId++;
        this._callbackMap[correspondenceId] = callback;
        var msg: IMessage = {
            name: "getprice",
            data: {},
            correspondenceId: correspondenceId.toString()
        };
        this._sendMessage(msg);
    }

    /**
     * collates layout settings from previous parameters to ensure backwards compatibility
     */
    private fillLayoutSettings() {
        this.args.layoutSettings = this.args.layoutSettings || {};
        if (!this.args.layoutSettings.hasOwnProperty("Show Pages")) {
            this.args.layoutSettings["Show Pages"] = this.args.showFields;
        }
        if (!this.args.layoutSettings.hasOwnProperty("Show Header")) {
            this.args.layoutSettings["Show Header"] = this.args.showConfigHeader;
        }
    }

    /** disposes of the iframe and any handlers */
    public dispose() {
        if (this._iframe) {
            this._iframe.remove();
            this._iframe = undefined;
        }
        if (this._logo) {
            this._logo.remove();
            this._logo = undefined;
        }
        this._messageQueue = [];
        this._loading = false;
        this._onload = undefined;
        if (this._messageHandler) {
            window.removeEventListener("message", this._messageHandler);
        }
        this._callbackMap = {};
        this.onMessage.remove();
        this.onSubmit.remove();
    }
}

export class SceneEmbed extends Embed<ISceneEmbedArguments> {

    constructor(args: ISceneEmbedArguments) {

        super(args);

        this.requireWebGL();
    }

    protected _getIFrameSrc() {
        let url = this.args.kbmaxUrl +
            "/scenes/" + this.args.sceneId.toString() +
            "?embedded=true&requestid=" + encodeURIComponent(this.args.elementSelector) +
            //"&showfields=" + (this.args.showFields !== false) +
            "&showheader=" + (this.args.showHeader !== false) +
            //"&showconfigheader=" + (this.args.showConfigHeader !== false) +
            "&showdrawer=" + (this.args.showDrawer !== false) +
            "&showmove=" + (this.args.showMove !== false) +
            "&idtheme=" + (this.args.idTheme || "") +
            "&fields=" + (this.args.fields ? encodeURIComponent(JSON.stringify(this.args.fields)) : "") +
            "&layoutsettings=" + (this.args.layoutSettings ? encodeURIComponent(JSON.stringify(this.args.layoutSettings)) : "") +            
            "&parameters=" + (this.args.parameters ? encodeURIComponent(JSON.stringify(this.args.parameters)) : "") +
            "&transparentbackground=" + (this.args.transparentBackground === true);

        return url;
    }
}


export class ConfiguratorEmbed extends Embed<IConfigEmbedArguments>
{
    private configuredProduct;

    constructor(args: IConfigEmbedArguments) {

        if (args.id) args.configuratorId = args.id; //for backwards compatibility


        super(args);

        this.configuredProduct = args.configuredProduct;
    }

    protected readyToLoadConfiguredProduct() {
        let message: IMessage = {
            name: "setconfiguredproduct",
            data: this.configuredProduct
        };
        this._iframe.contentWindow.postMessage(message, "*");
    }

    protected _getIFrameSrc() {
        var src = this.args.kbmaxUrl;
        if (this.args.configuredProductId) {
            src += "/quoteproducts/" + this.args.configuredProductId;
        }
        else {
            src += "/configurators/" + this.args.configuratorId.toString();
        }
        src += "?embedded=true&requestid=" + encodeURIComponent(this.args.elementSelector) +
            //"&showfields=" + (this.args.showFields !== false) +
            "&showheader=" + (this.args.showHeader !== false) +
            //"&showconfigheader=" + (this.args.showConfigHeader !== false) +
            "&showdrawer=" + (this.args.showDrawer !== false) +
            "&showmove=" + (this.args.showMove !== false) +
            "&currency=" + encodeURIComponent(this.args.currency || "") +
            "&idtheme=" + (this.args.idTheme || "") +
            "&fields=" + (this.args.fields ? encodeURIComponent(JSON.stringify(this.args.fields)) : "") +
            "&layoutsettings=" + (this.args.layoutSettings ? encodeURIComponent(JSON.stringify(this.args.layoutSettings)) : "") +                      
            "&parameters=" + (this.args.parameters ? encodeURIComponent(JSON.stringify(this.args.parameters)) : "");

        if (this.args.configuredProduct) {
            src += "&deferLoadedRule=true";
        }

        if (this.args.autoSsoLogin) {
            src += "&autoSsoLogin=true";
        }

        if (this.args.cookieToken) {
            src += "&cookieToken=" + encodeURIComponent(this.args.cookieToken);
        }

        if (this.args.kineticCompany) {
            src += "&kineticCompany=" + encodeURIComponent(this.args.kineticCompany);
        }

        if (this.args.kineticPlant) {
            src += "&kineticPlant=" + encodeURIComponent(this.args.kineticPlant);
        }

        if (this.args.kineticCustomerId) {
            src += "&kineticCustomerId=" + encodeURIComponent(this.args.kineticCustomerId);
        }

        if (this.args.kineticCustomerNumber) {
            src += "&kineticCustomerNumber=" + encodeURIComponent(this.args.kineticCustomerNumber);
        }

        if (this.args.kineticQuoteNumber) {
            src += "&kineticQuoteNumber=" + encodeURIComponent(this.args.kineticQuoteNumber);
        }

        if (this.args.kineticQuoteLine) {
            src += "&kineticQuoteLine=" + encodeURIComponent(this.args.kineticQuoteLine);
        }

        if (this.args.kineticOrderNumber) {
            src += "&kineticOrderNumber=" + encodeURIComponent(this.args.kineticOrderNumber);
        }

        if (this.args.kineticOrderLine) {
            src += "&kineticOrderLine=" + encodeURIComponent(this.args.kineticOrderLine);
        }

        if (this.args.kineticJobNumber) {
            src += "&kineticJobNumber=" + encodeURIComponent(this.args.kineticJobNumber);
        }

        if (this.args.kineticAssemblySeq) {
            src += "&kineticAssemblySeq=" + encodeURIComponent(this.args.kineticAssemblySeq);
        }

        if (this.args.kineticCallContext) {
            src += "&kineticCallContext=" + encodeURIComponent(this.args.kineticCallContext);
        }

        if (this.args.kineticUserId) {
            src += "&kineticUserId=" + encodeURIComponent(this.args.kineticUserId);
        }

        return src;
    }

    public setConfiguredProduct(configProduct: IConfiguredProduct, callback: () => void) {
        var correspondenceId = this._correspondenceId++;
        this._callbackMap[correspondenceId] = callback;
        var msg: IMessage = {
            name: "setConfiguredProduct",
            data: configProduct,
            correspondenceId: correspondenceId.toString()
        }
        this._sendMessage(msg);
    }

    public getConfiguredProduct(callback: (configProduct: IConfiguredProduct) => void) {
        var correspondenceId = this._correspondenceId++;
        this._callbackMap[correspondenceId] = callback;
        var msg: IMessage = {
            name: "getConfiguredProduct",
            data: {},
            correspondenceId: correspondenceId.toString()
        };
        this._sendMessage(msg);
    }

    /**
     * saves the product to the quote and then submits the quote.
     * @param callback
     */
    public saveProductAndSubmit(callback?: (quote) => void) {
        var correspondenceId = this._correspondenceId++;
        this._callbackMap[correspondenceId] = callback;
        var msg: IMessage = {
            name: "saveProductAndSubmit",
            data: {},
            correspondenceId: correspondenceId.toString()
        };
        this._sendMessage(msg);
    }

    /**
     * saves the product to the existing quote if one exists or to a new quote if it does not
     * @param callback
     */
    public saveProduct(callback?: () => void) {
        var correspondenceId = this._correspondenceId++;
        this._callbackMap[correspondenceId] = callback;
        var msg: IMessage = {
            name: "saveProduct",
            data: {},
            correspondenceId: correspondenceId.toString()
        };
        this._sendMessage(msg);
    }
}

//for backwards compatibility.  Can be removed in the future
export class Viewer3D extends SceneEmbed { }
export class KBMaxConfigurator extends ConfiguratorEmbed { }


//if an element has the kbmax attributes, then we load a kbmax embed automatically for them
var loadFromHtml = () => {
    var elements = document.querySelectorAll("[data-kbmax]");
    for (var i = 0; i < elements.length; i++) {
        //start a new viewer for each element that had the attribute
        var el = <HTMLElement>elements[i];
        //validation
        if (!el.id) {
            el.id = "viewer";
        }
        if (!el.hasAttribute("data-scene-id") && !el.hasAttribute("data-configurator-id")) {
            throw new Error("kbmax element must have the data-scene-id or data-configurator-id attribute");
        }
        var sceneId = Number(el.getAttribute("data-scene-id"));
        var sceneGuid = el.getAttribute("data-scene-guid");
        var configuratorId = Number(el.getAttribute("data-configurator-id"));
        var transparentBackground = (el.getAttribute("data-transparent-background") === "true");
        var showFields = !(el.getAttribute("data-show-fields") === "false");
        var showHeader = !(el.getAttribute("data-show-header") === "false");
        var showConfigHeader = !(el.getAttribute("data-show-config-header") === "false");
        var showDrawer = (el.getAttribute("data-show-drawer") === "true");
        var showMove = !(el.getAttribute("data-show-move") === "false");
        var bindToFormSelector = el.getAttribute("data-bind-to-form");
        var currency = el.getAttribute("data-currency");
        var idTheme = el.getAttribute("data-id-theme");
        var fields = el.getAttribute("data-fields");
        var layoutSettings = el.getAttribute("data-layout-settings");
        var parameters = el.getAttribute("data-parameters");

        if (configuratorId) {
            var configEmbed = new ConfiguratorEmbed({
                elementSelector: "#" + el.id,
                configuratorId: configuratorId,
                showFields: showFields,
                showHeader: showHeader,
                showConfigHeader: showConfigHeader,
                showDrawer: showDrawer,
                showMove: showMove,
                bindToFormSelector: bindToFormSelector,
                currency: currency,
                idTheme: idTheme && Number(idTheme),
                fields: JSON.parse(fields),
                layoutSettings: JSON.parse(layoutSettings),
                parameters: JSON.parse(parameters)
            });
        } else {
            var sceneEmbed = new SceneEmbed({
                elementSelector: "#" + el.id,
                sceneId: sceneId,
                sceneGuid: sceneGuid,
                transparentBackground: transparentBackground,
                showFields: showFields,
                showHeader: showHeader,
                showConfigHeader: showConfigHeader,
                showDrawer: showDrawer,
                showMove: showMove,
                bindToFormSelector: bindToFormSelector,
                idTheme: idTheme && Number(idTheme),
                fields: JSON.parse(fields),
                layoutSettings: JSON.parse(layoutSettings),
                parameters: JSON.parse(parameters)
            });
        }
    }
};

//for a quick load, we try right away (hopefully the script was referenced after the html)
loadFromHtml();

//we check again after load in case the user had the script before the html was there
document.addEventListener("DOMContentLoaded", () => {
    loadFromHtml();
});
