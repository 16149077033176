export interface IKbEventListener<T> {
    callback: (args?: T) => void;
    tag?: string;
}

export class KbEvent<T> {
    /** ordered list to hold the listeners sorted by their priority. The lower the number, the higher the priority */
    protected _listeners = [];

    public add(callback: (args?: T) => void, tag: string = null): void {
        this._listeners.push({ callback, tag});
    }

    public remove(callback?: (args?: T) => void): void {
        if (typeof callback === "function") {
            for (let i = 0; i < this._listeners.length; i++) {
                if (this._listeners[i].callback === callback) {
                    this._listeners.splice(i, 1);
                    break;
                }
            }
        } else {
            this._listeners.length = 0;
        }
    }

    /*
     * removes all listeners with the given tag
     */
    public removeByTag(tag: string): void {
        for (let i = 0; i < this._listeners.length; i++) {
            if (this._listeners[i].tag === tag) {
                this._listeners.splice(i, 1);
            }
        }
    }

    public trigger(args?: T): void {
        for (let l of this._listeners) {
            l.callback.apply(null, [args]);
        }
    }
}

